'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.SortableCategories = function SortableCategories() {

    'use strict';

    /**
     * jQuery elements
     * @private
     */

    /**
     * Has the class been initialized?
     * @private
     */

    var inited = false;

    /**
     * Initializes the class.
     * @public
     */
    var init = function init() {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        var sortableTables = document.querySelectorAll('.sortable-categories');

        sortableTables.forEach(function (sortableList) {
            new Sortable(sortableList, {
                handle: '.drag-handle',
                animation: 150,
                ghostClass: 'sortable-ghost',
                onEnd: function onEnd() {
                    var categoryIds = Array.from(sortableList.querySelectorAll('tr')).map(function (row) {
                        return row.getAttribute('data-cat-id');
                    });
                    var categoryType = sortableList.getAttribute('data-type');

                    fetch('/actions/site-module/default/reorder-categories', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                            'X-CSRF-Token': site.App.config('csrf')
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify({
                            categoryIds: categoryIds,
                            categoryType: categoryType
                        })
                    }).then(function (response) {
                        if (!response.ok) {
                            return response.json().then(function (err) {
                                throw err;
                            });
                        }
                        return response.json();
                    }).then(function (data) {
                        if (data.success) {} else {
                            throw new Error(data.message || 'Erreur inconnue');
                        }
                    }).catch(console.error);
                }
            });
        });

        return true;
    };

    // Expose public methods & properties
    return {
        init: init
    };
}();